import { useLottie } from "lottie-react";
import notFoundAnimation from "./not-found.json";

const NotFoundAnimation = () => {
  const options = {
    animationData: notFoundAnimation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return View;
};

export default NotFoundAnimation;
