import NotFoundAnimation from "./NotFoundAnimation";
import Link from "next/link";

export default function NotFound() {
  return (
    <div>
      <div className="mx-auto mb-2 flex justify-center items-center px-4 mt-12 sm:px-6 lg:px-8">
        <img className="block h-12 w-auto" src="/logo.svg" alt="Rantic" />
      </div>
      <div className="max-w-screen-md mx-auto text-center px-4 sm:px-6 lg:px-20">
        <NotFoundAnimation />
      </div>
      <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl mt-6 leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          404 Not Found
        </h2>
        <div className="mt-8 mb-16 flex justify-center">
          <div className="ml-3 inline-flex">
            <Link href="/">
              <a className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring focus:border-indigo-300 transition duration-150 ease-in-out">
                Back to Home Page
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
